import { useState, useEffect } from "react";
import { onValue } from "firebase/database";

import FirebaseDB from "../Services/FirebaseDB";
import ChampionshipDTO from "../Models/ChampionshipDTO";

const useChampionships = () => {
  const [championships, setChampionships] = useState<
    Record<string, ChampionshipDTO>
  >({});

  useEffect(() => {
    onValue(FirebaseDB.championshipsRef, (snapshot) => {
      const data = snapshot.val();
      setChampionships(data);
    });
  }, []);

  return championships;
};

export default useChampionships;
