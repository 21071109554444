import { FC } from "react";
import Surface from "../Models/Surface";

const surfaces = [Surface.Gravel, Surface.Snow, Surface.Tarmac];

const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

interface SurfaceFilterProps {
  selectedSurface: Surface | null;
  onSurfaceChange: (surface: Surface | null) => void;
}

const SurfaceFilter: FC<SurfaceFilterProps> = ({
  selectedSurface,
  onSurfaceChange,
}) => {
  const handleSurfaceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const surface = event.target.value as Surface;
    onSurfaceChange(surface || null);
  };

  return (
    <div className="mb-4">
      <label className="mr-4 w-8" htmlFor="surface-select">
        Surface:
      </label>
      <select
        className="form-control inline-block filter-select"
        id="surface-select"
        value={selectedSurface || ""}
        onChange={handleSurfaceChange}
      >
        <option value="">All</option>
        {surfaces.map((surface) => (
          <option key={surface} value={surface}>
            {capitalize(surface)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SurfaceFilter;
