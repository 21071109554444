import { useEffect, useState } from "react";
import { onValue } from "firebase/database";
import User from "../Models/User";
import { HotlapDTO } from "../Models/Hotlap";
import TrackFull from "../Models/TrackFull";
import FirebaseDB from "../Services/FirebaseDB";

const useHotlapData = () => {
  const [drivers, setDrivers] = useState<Record<string, User>>({});
  const [hotlapData, setHotlapData] = useState<HotlapDTO>({});
  const [stages, setStages] = useState<Record<string, TrackFull>>({});

  useEffect(() => {
    onValue(FirebaseDB.usersRef, (snapshot) => {
      const data = snapshot.val();
      setDrivers(data);
    });

    onValue(FirebaseDB.hotlapRef, (snapshot) => {
      const data = snapshot.val();
      setHotlapData(data);
    });

    onValue(FirebaseDB.stagesRef, (snapshot) => {
      const data = snapshot.val();
      setStages(data);
    });
  }, []);

  return { drivers, hotlapData, stages };
};

export default useHotlapData;
