import React, { useEffect, useState } from "react";
import { Icon, Nav, Site } from "tabler-react";
import { NavLink } from "react-router-dom";

import useChampionships from "../Hooks/useChampionships";

type subNavItem = {
  value: string;
  to?: string;
  icon?: string;
  LinkComponent?: React.ElementType;
  useExact?: boolean;
};

type navItem = {
  value: string;
  to?: string;
  icon?: string;
  active?: boolean;
  LinkComponent?: React.ElementType;
  subItems?: Array<subNavItem>;
  useExact?: string;
};

const navBarItems: Array<navItem> = [
  {
    value: "Hotlap",
    to: "/",
    icon: "user",
    LinkComponent: NavLink,
    useExact: "true",
  },
];

type Props = {
  children?: React.ReactNode;
};

const SiteWrapper: React.FC<Props> = ({ children }) => {
  const prefersDarkMode = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme")
      ? localStorage.getItem("theme") === "dark"
      : prefersDarkMode
      ? true
      : false
  );
  const championships = useChampionships();

  const handleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.setItem("theme", !isDarkMode ? "dark" : "light");
  };

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  const championShips = Object.values(championships);
  const navItems = [
    ...navBarItems,
    ...championShips.map((c) => {
      return {
        value: c.name,
        to: `/championship/${c.id}`,
        icon: "target",
        LinkComponent: NavLink,
        useExact: "false",
      };
    }),
  ];

  return (
    <Site.Wrapper
      headerProps={{
        alt: "RBR Stats",
        href: "/",
        imageURL: `/brand_${isDarkMode ? "dark" : "light"}mode.svg`,
        navItems: (
          <Nav.Item type="div" className="d-none d-md-flex">
            <div className="header-fetch-wrapper">
              <div className="ml-2 pointer" onClick={handleDarkMode}>
                <Icon name="sun" />
              </div>
            </div>
          </Nav.Item>
        ),
      }}
      navProps={{ itemsObjects: navItems }}
      footerProps={{
        copyright: <>Data may be up to an hour old.</>,
      }}
    >
      {children}
    </Site.Wrapper>
  );
};

export default SiteWrapper;
