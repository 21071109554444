import firebaseApp from "./Firebase";

import { getDatabase, ref } from "firebase/database";

const db = getDatabase(firebaseApp);

const championshipsRef = ref(db, "championships");
const hotlapRef = ref(db, "hotlap");
const stagesRef = ref(db, "stages");
const usersRef = ref(db, "users");

export default {
  championshipsRef,
  db,
  hotlapRef,
  usersRef,
  stagesRef,
};
