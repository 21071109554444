import { FC } from "react";

interface SortComponentProps {
  selectedSort: string;
  onSortChange: (sort: string) => void;
}

const HotlapSort: FC<SortComponentProps> = ({ selectedSort, onSortChange }) => {
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSortChange(event.target.value);
  };

  return (
    <div className="mb-4">
      <label className="mr-4 w-8" htmlFor="sort-select">
        Sort by:
      </label>
      <select
        className="form-control inline-block filter-select"
        id="sort-select"
        value={selectedSort}
        onChange={handleSortChange}
      >
        <option value="alphabetical">Alphabetical</option>
        <option value="diffPercent">Closest to stage record</option>
        <option value="length">Track length</option>
      </select>
    </div>
  );
};

export default HotlapSort;
