import React from "react";
import { Table } from "tabler-react";

import { determineVinner } from "../Lib/RallyUtils";
import { EIRIK_ID, SINDRE_ID } from "../Lib/GeneralUtils";

type Props = {
  data: any;
};

const Rally: React.FC<Props> = ({ data }) => {
  if (!data?.results) return null;

  const eirik = data.results.find((r: any) => r.userName === EIRIK_ID);
  const sindre = data.results.find((r: any) => r.userName === SINDRE_ID);

  const winner = determineVinner(eirik, sindre);
  const eirikWinner = winner === EIRIK_ID;
  const sindreWinner = winner === SINDRE_ID;

  return (
    <Table.Row key={data.name}>
      <Table.Col>{data.name}</Table.Col>
      <Table.Col>
        {eirikWinner ? (
          <strong className="text-green">{eirik?.position || "DNF?"}</strong>
        ) : (
          eirik?.position || "DNF?"
        )}
      </Table.Col>
      <Table.Col>
        {sindreWinner ? (
          <strong className="text-green">{sindre?.position || "DNF?"}</strong>
        ) : (
          sindre?.position || "DNF?"
        )}
      </Table.Col>
    </Table.Row>
  );
};

export default Rally;
