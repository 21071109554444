import React, { useEffect, useState } from "react";

import { EIRIK_ID, SINDRE_ID } from "../Lib/GeneralUtils";
import { determineVinner } from "../Lib/RallyUtils";
import DriverCardChampionship from "../Components/DriverCardChampionship";

type UserScores = {
  avgPosition: number | null;
  bestPosition: number;
  points: number;
  positions: number[];
};

const getInitialPointsState = (): Record<string, UserScores> => ({
  [EIRIK_ID]: {
    avgPosition: null,
    bestPosition: 0,
    points: 0,
    positions: [],
  },
  [SINDRE_ID]: {
    avgPosition: null,
    bestPosition: 0,
    points: 0,
    positions: [],
  },
});

const handleUser = (user: any, userId: string, points: any) => {
  if (user?.position) {
    points[userId].positions.push(user.position);
    if (
      points[userId].bestPosition === 0 ||
      points[userId].bestPosition > user.position
    ) {
      points[userId].bestPosition = user.position;
    }
  }

  if (points[userId].positions.length > 0) {
    points[userId].avgPosition =
      points[userId].positions.reduce(
        (acc: number, curr: number) => acc + curr,
        0
      ) / points[userId].positions.length;
  }
};

type Props = {
  data: any;
};

export const ChampionshipStandings: React.FC<Props> = ({ data }) => {
  const [userScores, setUserScores] = useState<any>(getInitialPointsState());
  useEffect(() => {
    if (!data) return;

    const rallies = Object.values(data);
    const newPoints = getInitialPointsState();

    rallies.forEach((r: any) => {
      const eirik = r.results.find((r: any) => r.userName === EIRIK_ID);
      const sindre = r.results.find((r: any) => r.userName === SINDRE_ID);

      const winner = determineVinner(eirik, sindre);

      if (winner === EIRIK_ID) {
        newPoints[EIRIK_ID].points += 1;
      } else if (winner === SINDRE_ID) {
        newPoints[SINDRE_ID].points += 1;
      }

      handleUser(eirik, EIRIK_ID, newPoints);
      handleUser(sindre, SINDRE_ID, newPoints);
    });
    setUserScores(newPoints);
  }, [data]);

  if (!data || !userScores) return null;

  const mostPoints =
    userScores[EIRIK_ID].points > userScores[SINDRE_ID].points
      ? EIRIK_ID
      : SINDRE_ID;

  return (
    <div className="row">
      <DriverCardChampionship
        userScores={userScores[EIRIK_ID]}
        mostPoints={mostPoints === EIRIK_ID}
        name="Eirik"
      />
      <DriverCardChampionship
        userScores={userScores[SINDRE_ID]}
        mostPoints={mostPoints === SINDRE_ID}
        name="Sindre"
      />

      <div className="exportFinished">&nbsp;</div>
    </div>
  );
};

export default ChampionshipStandings;
