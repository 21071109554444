import React from "react";
import { Table } from "tabler-react";

import { HotLapUserData } from "../Models/Hotlap";
import User from "../Models/User";
import { shortenCarName } from "../Lib/CarUtils";
import TrackFull from "../Models/TrackFull";

type Props = {
  drivers: Record<number, User>;
  hotlapData: HotLapUserData[];
  showHeader?: boolean;
  track: TrackFull;
};

const HotLapRow: React.FC<Props> = ({
  drivers,
  hotlapData,
  showHeader = false,
  track,
}) => {
  if (!hotlapData?.length) return null;

  return (
    <>
      <h3 className="h4 mt-5">
        {track.country && (
          <span
            className={`flag flag-${track.country.toLowerCase()} mr-2`}
          ></span>
        )}
        {track.name}
      </h3>
      <Table
        cards={true}
        className="table-vcenter"
        responsive={true}
        striped={true}
      >
        {showHeader && (
          <Table.Header>
            <Table.Row>
              <Table.ColHeader className="w-2">#</Table.ColHeader>
              <Table.ColHeader className="w-9">Name</Table.ColHeader>
              <Table.ColHeader className="w-9">Time</Table.ColHeader>
              <Table.ColHeader className="w-9">Diff (1.)</Table.ColHeader>
              <Table.ColHeader>Car</Table.ColHeader>
            </Table.Row>
          </Table.Header>
        )}

        <Table.Body>
          {hotlapData.map((hotlap, index) => (
            <Table.Row key={hotlap.userId}>
              <Table.Col className="w-2">{index + 1}</Table.Col>
              <Table.Col className="w-9">
                <strong>{drivers[hotlap.userId]?.name || " - "}</strong>
              </Table.Col>
              <Table.Col className="w-9">
                <strong>{hotlap.time}</strong>
              </Table.Col>
              <Table.Col className="w-9">
                {hotlap.diff} ({hotlap.diffPercent}%)
              </Table.Col>
              <Table.Col>{shortenCarName(hotlap.carName)}</Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
export default React.memo(HotLapRow);
