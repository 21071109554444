import { FC } from "react";

interface CarTypeFilterProps {
  selectedCarType: string | null;
  onCarTypeChange: (carType: string | null) => void;
}

const CarTypeFilter: FC<CarTypeFilterProps> = ({
  selectedCarType,
  onCarTypeChange,
}) => {
  const handleCarTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const carType = event.target.value;
    onCarTypeChange(carType || null);
  };

  return (
    <div className="mb-4">
      <label className="mr-4 w-8" htmlFor="car-type-select">
        Car:
      </label>
      <select
        className="form-control inline-block filter-select"
        id="car-type-select"
        value={selectedCarType || ""}
        onChange={handleCarTypeChange}
      >
        <option value="">All</option>
        <option value="WRC">WRC</option>
        <option value="Non-WRC">Non-WRC</option>
      </select>
    </div>
  );
};

export default CarTypeFilter;
