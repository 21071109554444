export const EIRIK_ID = "eirikh";
export const SINDRE_ID = "sindreda";

export function AlphabeticSort(
  a: any,
  b: any,
  key: number | string,
  descending = false
) {
  if (a[key] === null) a[key] = "";
  if (b[key] === null) b[key] = "";

  let order = 0;

  if (typeof a[key] == "string" && typeof b[key] == "string") {
    order = a[key].localeCompare(b[key], undefined, {
      numeric: true,
      sensitivity: "base",
    });
  } else {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA < valueB) {
      order = -1;
    } else if (valueA > valueB) {
      order = 1;
    }
  }

  if (descending === true) {
    order *= -1;
  }

  return order;
}
