import React from "react";

import User from "../Models/User";
import { HotlapDTO } from "../Models/Hotlap";
import { getNumberOfWinsForDriver } from "../Lib/HotLapUtils";

type Props = {
  driver: User;
  hotlapData: HotlapDTO;
};

const DriverCard: React.FC<Props> = ({ driver, hotlapData }) => {
  if (!driver) return null;

  const numWins = getNumberOfWinsForDriver(hotlapData, driver.id);

  return (
    <div className="col col-sm-6 col-lg-3">
      <div className="card p-3">
        <div className="d-flex align-items-center">
          <span className="stamp stamp-md bg-red mr-3">
            <i className="fe fe-user"></i>
          </span>
          <div>
            <h3 className="h4 m-0">{`${driver.name}: ${numWins}`}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverCard;
