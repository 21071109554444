import React from "react";

type CardProps = {
  mostPoints: boolean;
  name: string;
  userScores: any;
};

const Card: React.FC<CardProps> = ({ mostPoints, name, userScores }) => (
  <div className="col col-sm-6 col-lg-3">
    <div className="card">
      <div className="card-body text-center">
        {mostPoints && <div className="card-status bg-green"></div>}
        <div className="card-category">
          <strong>{name}</strong>
        </div>
        <div className="display-3 my-4">
          {mostPoints ? (
            <strong className="text-green">
              {userScores?.points || "0"} wins
            </strong>
          ) : (
            (userScores?.points || "0") + " wins"
          )}
        </div>
        <div>Best result: {userScores?.bestPosition || "-"}</div>
        <div>
          Avg. result:{" "}
          {userScores?.avgPosition ? userScores.avgPosition.toFixed(1) : "-"}
        </div>
      </div>
    </div>
  </div>
);

export default Card;
