import React, { useMemo } from "react";
import { HotlapDTO } from "../Models/Hotlap";
import {
  getTracksFromHotlapData,
  getUserResultsForTrack,
} from "../Lib/HotLapUtils";
import Track from "../Models/Track";
import HotLapRow from "./HotLapRow";
import User from "../Models/User";
import TrackFull from "../Models/TrackFull";

type Props = {
  drivers: Record<number, User>;
  hotlapData: HotlapDTO;
  sortCriteria: string;
  stages: Record<string, TrackFull>;
};

const HotLapList: React.FC<Props> = ({
  drivers,
  hotlapData,
  sortCriteria,
  stages,
}) => {
  const tracks: Track[] = useMemo(() => {
    return getTracksFromHotlapData(hotlapData, sortCriteria, stages);
  }, [hotlapData, sortCriteria, stages]);

  if (!hotlapData || !tracks?.length) return null;

  return (
    <div>
      {tracks.map((t, index) => (
        <HotLapRow
          drivers={drivers}
          hotlapData={getUserResultsForTrack(hotlapData, t.id)}
          key={t.id}
          showHeader={index === 0}
          track={{
            ...t,
            ...stages[t.id],
          }}
        />
      ))}
    </div>
  );
};

export default React.memo(HotLapList);
