import { HotLapUserData, HotlapDTO } from "../Models/Hotlap";
import Track from "../Models/Track";
import TrackFull from "../Models/TrackFull";
import { AlphabeticSort } from "./GeneralUtils";

export const getTracksFromHotlapData = (
  hotlapData: HotlapDTO,
  sortCriteria: string,
  stages?: Record<string, TrackFull>
) => {
  const trackList: Track[] = [];
  Object.values(hotlapData).forEach((userData) => {
    Object.values(userData).forEach((hotlap) => {
      const existingTrack = trackList.find((track) => track.id === hotlap.id);
      if (!existingTrack) {
        trackList.push({
          id: hotlap.id,
          name: hotlap.name,
          diffPercent: parseFloat(hotlap.diffPercent),
        });
      } else {
        // Update diffPercent to the lowest value
        existingTrack.diffPercent = Math.min(
          existingTrack.diffPercent,
          parseFloat(hotlap.diffPercent)
        );
      }
    });
  });

  // Sorting the trackList based on the selected criteria
  if (sortCriteria === "alphabetical") {
    trackList.sort((a, b) => a.name.localeCompare(b.name));
  } else if (sortCriteria === "diffPercent") {
    trackList.sort((a, b) => a.diffPercent - b.diffPercent);
  } else if (sortCriteria === "length" && stages) {
    trackList.sort((a, b) => stages[a.id].length - stages[b.id].length);
  }

  return trackList;
};

export const getUserResultsForTrack = (
  hotlapData: HotlapDTO,
  trackId: string
) => {
  const userResults: Record<string, HotLapUserData> = {};
  Object.entries(hotlapData).forEach(([key, userData]) => {
    if (userData[Number(trackId)]) {
      userResults[key] = {
        ...userData[Number(trackId)],
        userId: Number(key),
      };
    }
  });
  var sortedResult = Object.values(userResults).sort((a, b) =>
    AlphabeticSort(a, b, "time")
  );
  return sortedResult;
};

export const getNumberOfWinsForDriver = (
  hotlapData: HotlapDTO,
  driverId: number
) => {
  const tracks = getTracksFromHotlapData(hotlapData, "alphabetical");
  let numberOfWins = 0;
  tracks.forEach((track) => {
    const userResults = getUserResultsForTrack(hotlapData, track.id);
    if (!userResults) return;
    if (userResults[0].userId === driverId) {
      numberOfWins++;
    }
  });

  return numberOfWins;
};
