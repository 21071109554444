import { FC } from "react";
import ChampionshipDTO from "../Models/ChampionshipDTO";

interface RallyFilterProps {
  championships: Record<string, ChampionshipDTO>;
  selectedRally: string | null;
  onRallyChange: (rally: string | null) => void;
}

const RallyFilter: FC<RallyFilterProps> = ({
  championships,
  selectedRally,
  onRallyChange,
}) => {
  const handleRallyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const rally = event.target.value;
    onRallyChange(rally || null);
  };

  return (
    <div className="mb-4">
      <label className="mr-4 w-8" htmlFor="rally-select">
        Rally:
      </label>
      <select
        className="form-control inline-block filter-select"
        id="rally-select"
        value={selectedRally || ""}
        onChange={handleRallyChange}
      >
        <option value="">All</option>
        {Object.values(championships).flatMap((championship) =>
          Object.keys(championship.rallies).map((rallyName) => (
            <option key={rallyName} value={rallyName}>
              {`${championship.name}: ${rallyName}`}
            </option>
          ))
        )}
      </select>
    </div>
  );
};

export default RallyFilter;
