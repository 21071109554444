import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBOsn4EalqPjINWywdB18w5f0O9rUC9uXI",
  authDomain: "rbr-stats.firebaseapp.com",
  databaseURL: "https://rbr-stats-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rbr-stats",
  storageBucket: "rbr-stats.appspot.com",
  messagingSenderId: "59888227422",
  appId: "1:59888227422:web:ccf8d3868512cd401a10cf"
};

const app = initializeApp(firebaseConfig);

export default app;