import { type FC } from "react";
import Hotlap from "./Hotlap";
import useChampionships from "../Hooks/useChampionships";
import Championship from "./Championship";

const Export: FC = () => {
  const championships = useChampionships();
  return (
    <div>
      <Hotlap exportMode />
      <hr />
      {Object.values(championships).map((c) => (
        <Championship id={c.id} key={c.id} />
      ))}
      <hr />
      <div>
        <a href="https://rbr.mitt.land">https://rbr.mitt.land</a>
      </div>
    </div>
  );
};

export default Export;
