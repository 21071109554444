import { type FC, useState } from "react";
import HotLapList from "../Components/HotLapList";
import DriverCards from "../Components/DriverCards";
import Surface from "../Models/Surface";
import useChampionships from "../Hooks/useChampionships";
import useHotlapData from "../Hooks/useHotlapData";
import SurfaceFilter from "../Components/SurfaceFilter";
import RallyFilter from "../Components/RallyFilter";
import CarTypeFilter from "../Components/CarTypeFilter";
import SortComponent from "../Components/HotlapSort";

interface Props {
  exportMode?: boolean;
}

const Hotlap: FC<Props> = ({ exportMode = false }) => {
  const [selectedSurface, setSelectedSurface] = useState<Surface | null>(null);
  const [selectedRally, setSelectedRally] = useState<string | null>(null);
  const [selectedCarType, setSelectedCarType] = useState<string | null>(null);
  const [selectedSort, setSelectedSort] = useState<string>("alphabetical");

  const championships = useChampionships();
  const { drivers, hotlapData, stages } = useHotlapData();

  const filteredHotlapData = Object.fromEntries(
    Object.entries(hotlapData).map(([driverId, hotlaps]) => [
      driverId,
      Object.fromEntries(
        Object.entries(hotlaps).filter(([_, hotlap]) => {
          const stage = stages[hotlap.id];
          const surfaceMatch = selectedSurface
            ? stage?.surface === selectedSurface
            : true;
          const rallyMatch = selectedRally
            ? Object.values(championships).some((championship) =>
                Object.entries(championship.rallies).some(
                  ([rallyName, rallyData]) =>
                    rallyName === selectedRally &&
                    rallyData.stages.includes(stage?.id)
                )
              )
            : true;
          const carTypeMatch = selectedCarType
            ? (selectedCarType === "WRC" && hotlap.carName.includes("WRC")) ||
              (selectedCarType === "Non-WRC" && !hotlap.carName.includes("WRC"))
            : true;
          return surfaceMatch && rallyMatch && carTypeMatch;
        })
      ),
    ])
  );

  return (
    <div>
      <h2>Hotlap</h2>

      {!exportMode && (
        <div className="row">
          <div className="col-6">
            <SurfaceFilter
              selectedSurface={selectedSurface}
              onSurfaceChange={setSelectedSurface}
            />
          </div>
          <div className="col-6">
            <RallyFilter
              championships={championships}
              selectedRally={selectedRally}
              onRallyChange={setSelectedRally}
            />
          </div>
          <div className="col-6">
            <CarTypeFilter
              selectedCarType={selectedCarType}
              onCarTypeChange={setSelectedCarType}
            />
          </div>
          <div className="col-6">
            <SortComponent
              selectedSort={selectedSort}
              onSortChange={setSelectedSort}
            />
          </div>
        </div>
      )}

      <DriverCards drivers={drivers} hotlapData={filteredHotlapData} />
      <HotLapList
        drivers={drivers}
        hotlapData={filteredHotlapData}
        sortCriteria={selectedSort}
        stages={stages}
      />
    </div>
  );
};

export default Hotlap;
