import { Route, Routes } from "react-router-dom";
import { Page } from "tabler-react";

import "tabler-react/dist/Tabler.css";
import "react-popper-tooltip/dist/styles.css";

import SiteWrapper from "./Containers/SiteWrapper";
import Hotlap from "./Containers/Hotlap";
import Championship from "./Containers/Championship";
import Export from "./Containers/Export";

function App() {
  return (
    <div className="App">
      <SiteWrapper>
        <Page.Content>
          <Routes>
            <Route path="/" element={<Hotlap />} />
            <Route
              path="/championship/:championshipId"
              element={<Championship />}
            />
            <Route path="/export" element={<Export />} />
          </Routes>
        </Page.Content>
      </SiteWrapper>
    </div>
  );
}

export default App;
