import React from "react";
import { Grid } from "tabler-react";

import { HotlapDTO } from "../Models/Hotlap";
import User from "../Models/User";
import DriverCard from "./DriverCard";

type Props = {
  drivers: Record<number, User>;
  hotlapData: HotlapDTO;
};

const DriverCards: React.FC<Props> = ({ drivers, hotlapData }) => {
  if (!drivers) return null;

  return (
    <Grid.Row cards={true} className="top-row">
      {Object.values(drivers).map((driver) => (
        <DriverCard key={driver.id} driver={driver} hotlapData={hotlapData} />
      ))}
    </Grid.Row>
  );
};

export default DriverCards;
