import React, { useEffect, useState } from "react";
import { Unsubscribe, onValue, query, ref } from "firebase/database";
import { Table } from "tabler-react";
import { useParams } from "react-router-dom";

import useChampionships from "../Hooks/useChampionships";
import FirebaseDB from "../Services/FirebaseDB";

import ChampionshipStandings from "./ChampionshipStandings";
import Rally from "./Rally";

type Props = {
  id?: number;
};

const Championship: React.FC<Props> = ({ id }) => {
  const [data, setData] = useState<any>({});
  const championships = useChampionships();

  const routeChampionshipId = useParams<{ championshipId: string }>()
    .championshipId;
  const championshipId = id || routeChampionshipId;

  useEffect(() => {
    let unsub: Unsubscribe = () => {};
    if (championshipId) {
      const cDataRef = ref(FirebaseDB.db, `championshipData/${championshipId}`);

      const cQueryRef = query(cDataRef);

      unsub = onValue(cQueryRef, (snapshot) => {
        setData(snapshot.val());
      });
    }

    return () => unsub();
  }, [championshipId]);

  if (!data || !championshipId) return null;

  const championship = championships[championshipId];

  const rallies = Object.values(data);

  return (
    <div>
      <h2>{championship?.name || "-"}: Total Standings</h2>
      <ChampionshipStandings data={data} />

      <h3>Rally results</h3>

      <Table className="table-vcenter" responsive={true}>
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Rally</Table.ColHeader>
            <Table.ColHeader>Eirik</Table.ColHeader>
            <Table.ColHeader>Sindre</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rallies.map((r: any) => (
            <Rally data={r} key={r.id} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default Championship;
