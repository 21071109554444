export const shortenCarName = (carName: string) => {
  const parts = carName.split(" ");
  // Take only the first three words or all parts if fewer than three
  const shortenedParts = parts.slice(0, 3);
  const yearRegex = /\b\d{4}\b/;

  // Check if the last part is a year and move it to the end if true
  if (yearRegex.test(parts[parts.length - 1])) {
    const year = parts.pop()!;
    shortenedParts.splice(2, 1, year);
  }

  return shortenedParts.join(" ");
};
